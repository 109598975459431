<template>
<div>
  <div class="pa-2 w-255">
    <v-tabs v-model="activeTab" height="36" class="horizontal-tab tabsegment c-b-white">
        <v-tab height="28" href="#position" @click="getPositions('positions')" color="#ffffff" >Positions</v-tab>
        <v-tab height="28" href="#grouped" @click="getPositions('grouped')" color="#ffffff" >Group</v-tab>
    </v-tabs>
  </div>
      <v-tabs-items class="transparent-body" v-model="activeTab">
         <v-tab-item id="position" :transition="false">
           <v-simple-table class="mx-2 a-box mb-2" v-if="positionBook.length != 0">
           <thead>
           <tr>
           <th width="20" class="text-center"><v-checkbox v-model="checkBoxSelectAll" :ripple="false"></v-checkbox></th>
           <th max-width="60" class="text-center">Type</th>
           <th class="caption f-light text-left">Instrument</th>
           <th class="caption f-light text-right">Qty</th>
           <th class="caption f-light text-right">Buy Avg.</th>
           <th class="caption f-light text-right">Sell Avg.</th>
           <th class="caption f-light text-right">LTP</th>
           <th class="caption f-light text-right">MTM</th>
           </tr>
      </thead> 
        <tbody>
          <tr class="positionsTable" v-for="(item, index) in positionBook" :key="index" >
            <td width="20" class="text-center"><v-checkbox v-model="selectedCheckbox" :value="item" :ripple="false"></v-checkbox></td>
            <td max-width="60" class="text-center pa-4 subtitle-2"><span :class="item.Pcode == 'MIS' ? 'misclr' : 'nrmlclr'" 
             >{{ item.Pcode }}</span></td>
            <td class="text-left"><span class="alignfields text-center">{{ item.Tsym }} <span class="caption">{{ item.Exchange }}</span></span></td>
            <td class="text-right subtitle-2">{{ item.Netqty }}</td>
            <td class="text-right subtitle-2">{{ item.NetBuyavgprc }}</td>
            <td class="text-right subtitle-2">{{ item.NetSellavgprc }}</td>
            <td class="text-right subtitle-2">{{ item.LTP }}</td>
            <td class="text-right subtitle-2" :class="item.MtoM >= 0? 'negativevalue': 'positivevalue'">{{ item.MtoM }}</td>
          </tr>
        </tbody>
        <tfoot v-if="selectedCheckbox.length > 0">
          <td colspan="6" class="pa-4 subtitle-2"><v-btn depressed height="32" class="text-caption text-capitalize primary rounded-md">Exit All</v-btn></td>
          <td class="pa-4 subtitle-2 text-right">Total P&L </td>
          <td class="pa-4 subtitle-2"><span class="float-right" :class="positionTotalPnl >= 0? 'negativevalue': 'positivevalue'">{{positionTotalPnl}}</span></td>
        </tfoot>
       </v-simple-table>
         </v-tab-item>
         <v-tab-item id="grouped" :transition="false">
           <v-flex 8 class="px-2" >
          <div>
          <v-card class="a-box ma-2 ml-0 mr-0 mt-0" v-for="(group, index) in positionGroupedData" :key="index">
              <v-flex>
                <v-row class="ma-0">
                  <v-card-title class="subtitle-1 pa-0 ml-7 mt-5">
                    <span class="f-semi-18-12" >{{ index }}</span>
                    <span class="caption f-light ml-2" :class="group.percentage >= 0 ? 'negativevalue' : 'positivevalue'">{{ group.percentage }}</span>
                  </v-card-title>
                  <!-- <div>
                    <span class="ml-6">
                      <img class="light-th mt-6" src="@/assets/images/icons/Graph.svg" />
                      <img class="dark-th mt-6" src="@/assets/images/icons/graph-d.svg" />
                    </span>
                    <span class="ml-4">
                      <img class="light-th mt-6" src="@/assets/images/icons/Info.svg" />
                      <img class="dark-th mt-6" src="@/assets/images/icons/info-d.svg" />
                    </span>
                  </div> -->
                  <v-spacer></v-spacer>
                  <div>
                    <v-menu max-width="250" :left="true" class="pa-3" offset-y transition="none" open-on-hover rounded>
                      <template v-slot:activator="{ on, attrs }">
                        <label class="mr-7 mt-6 float-right caption pa-0" v-bind="attrs" v-on="on"> 
                            Show more
                            <svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img" aria-hidden="true" class="" fill="#929292"
                                style="position:relative;top:3px; height: 14px; width: 14px;">
                              <path  d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"></path>
                            </svg>
                            <span class="align-center align-content-start"></span>
                        </label>
                      </template>
                      <v-list>
                        <div class="pa-2" v-for="(s, i) in items" :key="i">
                          <p class="caption f-light"> Delta <span class="float-right ml-8">{{ s.delta}}</span></p>
                          <p class="caption f-light"> Gamma <span class="float-right ml-8">{{ s.gamma}}</span></p>
                          <p class="caption f-light"> Vega <span class="float-right ml-8">{{ s.vega}}</span></p>
                        </div>
                      </v-list>
                    </v-menu>
                  </div>
                </v-row>
              </v-flex>
              <v-flex>
                <v-row class="ma-0">
                  <v-col class="pa-0 ml-7 mt-3 mb-3">
                    <!-- <v-btn text-lowercase x-small  class="ml-0 pa-2 text-capitalize mb-0" outlined color="#2EA6F5">Show all</v-btn>
                    <span class="ml-5">
                      <v-btn x-small  class="ml-0 pa-2 text-capitalize" outlined color="#7E7E7E" style="border-color: #E1E1E1;">02 Sep</v-btn>
                    </span> -->
                    <v-slide-group v-model="showType" mandatory class="pa-0" hide-arrows >
                      <v-slide-item class="selection" v-for="item in showTypes" :key="item" v-slot="{ active, toggle }">
                        <v-btn outlined depressed :color="active ? 'primary' : 'grey lighten-1'"
                          height="20" min-width="60" class="caption rounded-md" :value="item" @click="toggle">
                          {{ item }}
                        </v-btn>
                      </v-slide-item>
                    </v-slide-group>
                  </v-col>
                </v-row>
              </v-flex>
              <v-flex class="pt-0">
                <v-row class="ma-0">
                  <v-col class="pa-0">
                    <v-simple-table class="pa-4">
                      <template>
                        <thead>
                          <tr class="pa-3">
                            <th class="text-left pa-0" width="20">
                              <v-checkbox :ripple="false"></v-checkbox>
                            </th>
                            <th class="text-center pa-0 caption f-light">Type</th>
                            <th class="pa-0 caption f-light">Name</th>
                            <th class="text-right pa-0 caption f-light">Qty</th>
                            <th class="text-right pa-0 caption f-light">Avg</th>
                            <th class="text-right pa-0 caption f-light">LTP</th>
                            <th class="text-right pa-0 caption f-light">P/L</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="pa-0" height="0" v-for="(item,index) in group" :key="index">
                            <td class="text-left pa-0">
                              <v-checkbox :ripple="false"></v-checkbox>
                            </td>
                            <td class="text-center pa-0">
                             <span :class="item.Pcode == 'MIS' ? 'misclr' : 'nrmlclr'">{{ item.Pcode }}</span>
                            </td>
                            <td class="pa-0 text-left" rounded>
                              <span class="subtitle">{{ item.Tsym }}</span>
                              <span class="ml-2 caption" color="#5e5e5e">{{ item.Exchange}}</span>
                            </td>
                            <td class="pa-0 text-right subtitle" :class="item.Netqty == '159'? 'qtyclr': item.Netqty == '189'? 'qtyclr': item.Netqty == '345'? 'qtyclr': ''"> {{ item.Netqty }} </td>
                            <td class="pa-0 text-right subtitle"> {{ item.Buyavgprc }} </td>
                            <td class="pa-0 text-right subtitle"> {{ item.LTP }} </td>
                            <td class="pa-0 text-right subtitle" :class="item.MtoM >= 0? 'positivevalue': 'negativevalue'"> {{ item.MtoM }} </td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <th class="text-left pa-0 body-2" colspan="6">{{index}} - Total P&L</th>
                            <td class="pa-0 text-right subtitle" > {{  }} </td>
                          </tr>
                        </tfoot>
                      </template>
                    </v-simple-table>
                  </v-col>
                </v-row>
              </v-flex>
          </v-card>
          </div>
        </v-flex>
         </v-tab-item>
      </v-tabs-items>
      <div class="d-flex justify-center align-center no-data" v-if="positionGroupedData.length == 0 || positionBook.length == 0">
            NO DATA FOUND
          </div>
    </div>
</template>

<script>
import { mapState ,mapGetters} from "vuex"
export default {
  data: () => ({
    activeTab:4,
    items: [{ delta: "11(0.2 FUT)", gamma: "0.097", vega: "144" }],
    showTypes:['Show All' ,'02Sep'],
    showType: 'Show All',
    selectedCheckbox:[],
  }),
  computed: {
      ...mapState('positions',['funds','positionGroupedData','positionBook','positionTotalPnl']),

      checkBoxSelectAll: {
        get: function () {
             return this.positionBook
               ? this.selectedCheckbox.length == this.positionBook.length
               : false;
           },
           set: function (value) {
             var selected = [];
             if (value) {
               this.positionBook.forEach(function (item) {
                 selected.push(item);
               });
             }
             this.selectedCheckbox = selected;
           },
      },
    },
    methods: {
      async showOrderWindow(item, orderType) {
        this.$store.commit('orderWindow/setOrderType',await orderType);
        this.$store.commit('orderWindow/setToggleOrder',await orderType == 'Buy' ? true : false);
        this.$store.commit('orderWindow/setOrderWindow',await true);
    },
     getPositions(selectedTab) { 
         selectedTab == 'positions' ? this.activeTab = 0 : selectedTab == 'grouped' ? this.activeTab = 1 : '' 
         this.$router.push({ path: this.$router.history.current.fullPath, query: { tab: selectedTab } }).catch(() => {});
      },
      async intialFunctions() {
         await this.$store.dispatch('positions/fetchPositionBook');
         await this.$store.dispatch('positions/getFundsData');
         var currentPath =await this.$route.query.tab
         currentPath ? this.getPositions(currentPath) : this.getPositions(currentPath)
         this.selectedCheckbox = await [];
      }
    },
    watch: {
      $route(to, from) {
      setTimeout(() => {
       this.selectedCheckbox = []
      },100)
      console.log(to)
      }
    },
    mounted() {
      this.intialFunctions();
    },
}
</script>

<style scoped>
    .v-application .caption{color: #929292!important;}
    .misclr {color: #e2ad0d;background-color: #fdf7ef;padding: 4px 16px;font-size: 10px;border-radius: 3px;}
    .nrmlclr {color: #2629c3;background-color: #f2f3ff;padding: 4px 16px;font-size: 10px;border-radius: 3px;}
    .qtyclr {color: #2ea6f5 !important;}
    /* .negativevalue {color: #00b894 !important;}
    .positivevalue {color: #d63031 !important;} */
    .theme--dark .instrument{background: #272727;border: 1px solid #363636;}
    .theme--dark .instrument .tysmdata{color: #2ea6f5;}
    .selection:nth-child(2){margin-left:8px !important;}
    .theme--dark .misclr{background-color: #1e1e1e;}
    .v-application.theme--dark .c-b-white{background-color: #1e1e1e;}
    .transparent-body { background: transparent !important}
    .w-255{width:255px}
</style>